export default {
  "integration-overview": "Integration Overview",
  "using-the-javascript-sdk": "JavaScript SDK",
  "using-react-native-sdk": "React Native SDK",
  "using-swift-sdk": "Swift SDK",
  "using-kotlin-sdk": "Kotlin SDK",
  "using-server-side-sdk": "Server Side SDK",
  "reverse-engineering-apis": "Reverse Engineering APIs",
  "manifest-walkthrough": "Manifest Walkthrough",
};
