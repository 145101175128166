export default {
  index: "Overview",
  "first-web-proof": "First Web Proof",

  extension: {
    display: "hidden",
  },
  "public-data": "Public Data ",
  "authenticated-data": "Authenticated Data",
  "example-github-repo": "Example Github Repo",
  "verifiable-email": {
    display: "hidden",
  },
};
