import meta from "../../../pages/_meta.js";
import concepts_meta from "../../../pages/concepts/_meta.js";
import getting_started_meta from "../../../pages/getting-started/_meta.js";
import guides_meta from "../../../pages/guides/_meta.js";
import reference_meta from "../../../pages/reference/_meta.js";
import reference_circuits_meta from "../../../pages/reference/circuits/_meta.js";
export const pageMap = [{
  data: meta
}, {
  name: "404",
  route: "/404",
  frontMatter: {
    "sidebarTitle": "404"
  }
}, {
  name: "concepts",
  route: "/concepts",
  children: [{
    data: concepts_meta
  }, {
    name: "circuits",
    route: "/concepts/circuits",
    frontMatter: {
      "title": "Circuits",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "faq",
    route: "/concepts/faq",
    frontMatter: {
      "title": "FAQ",
      "description": "Pluto is on a mission to solve the challenges of today’s blockchain applications using applied cryptography."
    }
  }, {
    name: "history",
    route: "/concepts/history",
    frontMatter: {
      "title": "History",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "limitations-and-outlook",
    route: "/concepts/limitations-and-outlook",
    frontMatter: {
      "title": "Limitations",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "mpc-mode",
    route: "/concepts/mpc-mode",
    frontMatter: {
      "title": "MPC Mode",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "origo",
    route: "/concepts/origo",
    frontMatter: {
      "title": "Origo",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "tees",
    route: "/concepts/tees",
    frontMatter: {
      "title": "TEE Mode",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "web-proofs",
    route: "/concepts/web-proofs",
    frontMatter: {
      "title": "Web Proofs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "getting-started",
  route: "/getting-started",
  children: [{
    data: getting_started_meta
  }, {
    name: "authenticated-data",
    route: "/getting-started/authenticated-data",
    frontMatter: {
      "title": "Web Proof for Authenticated Data",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "example-github-repo",
    route: "/getting-started/example-github-repo",
    frontMatter: {
      "title": "Example Github Repo",
      "description": "Example implementations of Pluto's web-proofs library"
    }
  }, {
    name: "extension",
    route: "/getting-started/extension",
    frontMatter: {
      "title": "Pluto Chrome Extension",
      "description": "Integrate Pluto's proof generation capabilities into your web applications using our Chrome extension."
    }
  }, {
    name: "first-web-proof",
    route: "/getting-started/first-web-proof",
    frontMatter: {
      "title": "First Web Proof",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "index",
    route: "/getting-started",
    frontMatter: {
      "title": "Getting Started",
      "description": "Pluto is on a mission to solve the challenges of today's blockchain applications using applied cryptography"
    }
  }, {
    name: "public-data",
    route: "/getting-started/public-data",
    frontMatter: {
      "title": "Web Proof for Public Data",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "verifiable-email",
    route: "/getting-started/verifiable-email",
    frontMatter: {
      "title": "Verifiable Email",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "guides",
  route: "/guides",
  children: [{
    data: guides_meta
  }, {
    name: "integration-overview",
    route: "/guides/integration-overview",
    frontMatter: {
      "title": "Integration Overview",
      "description": "This short guide will walk you through the process of integrating the Web Proofs SDK."
    }
  }, {
    name: "manifest-walkthrough",
    route: "/guides/manifest-walkthrough",
    frontMatter: {
      "title": "Manifest Walkthrough",
      "description": "A step-by-step guide to creating and understanding Pluto manifest files"
    }
  }, {
    name: "reverse-engineering-apis",
    route: "/guides/reverse-engineering-apis",
    frontMatter: {
      "title": "Reverse Engineering APIs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "using-kotlin-sdk",
    route: "/guides/using-kotlin-sdk",
    frontMatter: {
      "title": "Using the Kotlin SDK",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "using-react-native-sdk",
    route: "/guides/using-react-native-sdk",
    frontMatter: {
      "title": "Using the React Native SDK",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "using-server-side-sdk",
    route: "/guides/using-server-side-sdk",
    frontMatter: {
      "title": "Using the Server Side SDK",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "using-swift-sdk",
    route: "/guides/using-swift-sdk",
    frontMatter: {
      "title": "Using the Swift SDK",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "using-the-javascript-sdk",
    route: "/guides/using-the-javascript-sdk",
    frontMatter: {
      "title": "Using the JavaScript & React SDKs",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}, {
  name: "index",
  route: "/",
  frontMatter: {
    "title": "Introduction",
    "description": "Pluto is on a mission to solve the challenges of today's blockchain applications using applied cryptography"
  }
}, {
  name: "manifest-builder",
  route: "/manifest-builder",
  frontMatter: {
    "title": "Manifest Builder",
    "description": "Pluto brings accessible cryptography to all application developers."
  }
}, {
  name: "other-resources",
  route: "/other-resources",
  frontMatter: {
    "title": "Other Resources",
    "description": "Pluto brings accessible cryptography to all application developers."
  }
}, {
  name: "reference",
  route: "/reference",
  children: [{
    data: reference_meta
  }, {
    name: "circuits",
    route: "/reference/circuits",
    children: [{
      data: reference_circuits_meta
    }, {
      name: "folding",
      route: "/reference/circuits/folding",
      frontMatter: {
        "title": "Folding",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }, {
      name: "http-verification",
      route: "/reference/circuits/http-verification",
      frontMatter: {
        "title": "HTTP Verification",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }, {
      name: "json-extraction",
      route: "/reference/circuits/json-extraction",
      frontMatter: {
        "title": "JSON Extraction",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }, {
      name: "plaintext-authentication",
      route: "/reference/circuits/plaintext-authentication",
      frontMatter: {
        "title": "Plaintext Authentication",
        "description": "Pluto brings accessible cryptography to all application developers."
      }
    }]
  }, {
    name: "javascript-sdk",
    route: "/reference/javascript-sdk",
    frontMatter: {
      "title": "JavaScript SDK Reference",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "manifest-reference",
    route: "/reference/manifest-reference",
    frontMatter: {
      "title": "Manifest Reference",
      "description": "A comprehensive reference for Pluto manifest files."
    }
  }, {
    name: "onchain-verifier-contracts",
    route: "/reference/onchain-verifier-contracts",
    frontMatter: {
      "title": "Onchain Verifier Contracts",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }, {
    name: "react-sdk",
    route: "/reference/react-sdk",
    frontMatter: {
      "title": "React SDK Reference",
      "description": "Pluto brings accessible cryptography to all application developers."
    }
  }]
}];