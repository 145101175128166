export default {
  "web-proofs": "Web Proofs",
  "limitations-and-outlook": "Limitations and Outlook",
  origo: "Web Proof Techniques: Origo Mode",
  tees: "Web Proof Techniques: TEE Mode",
  "mpc-mode": "Web Proof Techniques: MPC Mode",
  circuits: "Circuits",
  history: "History",
  faq: "FAQ",
};
